<mat-toolbar color="primary" class="mat-elevation-z5">
  <mat-toolbar-row class="toolbar-row" style="min-width: 1000px; max-width: 1500px; margin: auto">
    <button mat-icon-button (click)="sidenav.toggle()" *ngIf="false">
      <mat-icon>menu</mat-icon>
    </button>
    <img src="assets/acc" class="accsaber-icon" alt="accsaber-logo" />
    <a mat-button *ngFor="let link of links" [routerLink]="link.linkPath" class="links">
      {{ link.linkName }}
    </a>
    <div class="spacer"></div>
    <button mat-button (click)="showSignupDialog()" *ngIf="!playerName">Sign up</button>
    <a *ngIf="!!playerName" class="player" [routerLink]="'player-profile/' + playerId" mat-ripple>
      <p>{{ playerName }}</p>
      <img src="{{ imageUrl }}/avatars/{{ playerId }}.jpg" alt=" " />
    </a>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container style="height: calc(100% - 64px - 36px)">
  <mat-sidenav
    [autoFocus]="false"
    #sidenav
    class="mat-elevation-z5"
    style="width: 350px"
    mode="side"
  >
    <mat-nav-list *ngIf="false">
      <div *ngFor="let link of links">
        <a
          mat-list-item
          [routerLink]="link.linkPath"
          routerLinkActive="current-page"
          [disabled]="link.disable"
        >
          {{ link.linkName }}
        </a>
        <mat-divider></mat-divider>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div
      style="
        min-width: 1000px;
        max-width: 1500px;
        overflow: auto;
        height: 100%;
        min-height: 830px;
        margin: auto;
      "
    >
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<div class="beta" *ngIf="isBeta">BETA VERSION</div>

<div class="accsaber-footer">
  <a mat-button routerLink="privacy">Privacy</a>
  <a mat-button href="https://discord.gg/zd8W4rr" target="_blank"> Discord </a>
  <div class="spacer"></div>
  <button mat-button (click)="this.switchTheme()" class="align-right">Toggle Dark Theme</button>
</div>
